// @ts-nocheck
import React, { useEffect, useState, useContext, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled, { css } from 'styled-components/macro'
import BackdropBase from '@material-ui/core/Backdrop'
import queryString from 'query-string'
import { isEmpty } from 'lodash-es'
import Banners, { type Banner } from 'components/deals/Banners'
import LimitedOffersSection from 'components/home/LimitedOffersSection'
import GlobalMessage from 'components/common/GlobalMessage'
import SubHeader from 'components/header/SubHeader'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { StoreContext } from 'contexts/StoreContext'
import { useMediaQuery } from 'react-responsive'
import LimitedTimeOfferLoader from 'components/common/placeholder/LimitedTimeOfferLoader'
import ReactNativeComms from 'utils/reactNativeComms'
import {
	getTranslatedTextByKey,
	localeToCode,
	formatLocalDateTime,
	sendRequest,
	getECommerceDomainByEnv,
	getDomainByEnv,
	redirectToChainMenu,
	initOrUpdateSession,
	getStoreName,
} from 'utils/utils'
import SignedOut from 'components/AccountSteps/Steps/SignedOut'
import AccountDeleted from 'components/AccountSteps/Steps/AccountDeleted'
import { sendCustomEvent, sendEnhancedEcommerceEvent, bannerViewedMap } from 'utils/analytics/analytics'
import WithInView from 'HOCs/withInView'
import { mediaQueriesBreakpoints, upToTablet } from 'styles/mixins'

import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import ImageComponent from 'themes/ImageComponent'
import BrowseCategoriesSection from 'components/home/Categories/BrowseCategoriesSection'
import NewHeaderLayout from 'components/home/NewHomePageHeader/NewHeaderLayout'
import Layout from 'components/common/Layout'
import PastOrdersSection from 'static/components/home/PastOrdersSection'
import FeatureFlag from 'components/common/FeatureFlag'
import { FeatureFlagEnum } from 'types/constants.types'

import { useStores } from 'hooks/useStores'
import { extractCouponCodeFromOffer } from 'mobx/CouponFlow/utils'
import { useRouter } from 'next/router'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const EEE = CONSTANTS.ANALYTICS.ENHANCED_ECOMMERCE_EVENTS
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;

	${({ $isBackdropEnabled }) =>
		$isBackdropEnabled &&
		css`
			width: 100%;
			height: 100%;
			z-index: 98;
		`};

	@media (min-width: 1024px) {
		min-height: 93.1vh;
	}

	@media (max-width: 768px) {
		min-height: 100vh;
	}

	@media ${upToTablet} {
		padding-bottom: ${({ $isEcommerceFooterVisible }) => ($isEcommerceFooterVisible ? '0px' : '70px')};
	}
`

const LTOLayout = styled(Layout)`
	padding: 0 20px;

	@media (max-width: 899px) {
		padding: 0;
	}
`

const SpaceRemover = styled.div`
	margin-bottom: -20px;
`

const Backdrop = styled(BackdropBase)`
	z-index: 1201;
	background-color: rgba(0, 0, 0, 0.8);
	backdrop-filter: blur(3px);
`
const StyledLimitedOffersLoaderContainer = styled.div`
	display: flex;
	margin-top: ${({ $isMobileView }) => ($isMobileView ? '51px' : '121px')};
	margin-bottom: 43px;
	width: 100%;
	justify-content: center;
`

const ErrorMsg = styled.span`
	text-transform: capitalize;
`

const StoreClosedDialogImg = (props) => <ImageComponent asset="/images/pastOrdersEmpty.png" {...props} />

export const HomeContent = inject(
	'Infra',
	'User',
	'Home',
	'Application',
	'Account',
	'Cart',
	'AddressManager',
	'MobileApplication'
)(
	observer((props) => {
		const { Infra, User, Home, Application, isGeneratedStatically, content, Account, Cart, AddressManager, MobileApplication } = props
		const { logo, locale, branchesList, featuredItems } = Home
		const [isStoresClosedDialog, setIsStoresClosedDialog] = useState(false)
		const [dealsData, setDealsData] = useState(false)
		const [limnitedOffersState, setLimnitedOffersState] = useState({
			// at first render, we display the LTO of delivery order
			items: featuredItems.msg && featuredItems.msg.items[ORDER_TYPES.DELIVERY],
			randomStore: featuredItems.msg && { ...featuredItems.msg, locale: locale.msg },
		})
		const { backdropEnabled } = Home
		const isStoreClosed = Home.areAllStoresClosed()
		const { store } = useContext(StoreContext)
		const router = useRouter()

		useEffect(() => {
			const queryParams = queryString.parse(window.location.search)

			if (queryParams.signoutAction) {
				const isSignoutAction = queryParams.signoutAction === 'signout'
				const _localDateTime = formatLocalDateTime(Infra.locale.msg)

				const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				sendCustomEvent({
					category: 'account',
					action: isSignoutAction ? 'sign out' : 'delete',
					label: 'success',
					date: _localDateTime,
					storeID: store?.data?.id || '',
					storeName: getStoreName(store, orderType, Home) || '',
				})

				Infra.setNotification({
					open: true,
					message: isSignoutAction ? <SignedOut /> : <AccountDeleted />,
					cancelAction: () => {
						Infra.closeNotification()
					},
					okAction: () => {
						Infra.closeNotification()
						Account.openSignUpPopUp(true, { onSignupPopupClose: () => {} })
					},
					okText: isSignoutAction
						? getTranslatedTextByKey('eCommerce.signIn.text', 'Sign In')
						: getTranslatedTextByKey('eCommerce.signUp.text', 'Sign up'),
					showCancelButton: false,
					showCloseIcon: true,
				})
				window.history.replaceState({}, '', '/')
			}

			if (queryParams.em) {
				// Show possible error (could be redirected from start_web_session
				const errorMsg = decodeURIComponent(queryParams.em)
				const title = getTranslatedTextByKey('webviewFlow.error')
				Infra.setNotification({
					title: (
						<>
							<WarningIcon color="secondary" key={1} />
							<ErrorMsg key={2}>{title}!</ErrorMsg>
						</>
					),
					message: (
						<Typography key={3} variant="caption">
							{errorMsg}
						</Typography>
					),
					okAction: async () => {
						Infra.closeNotification()
					},
				})
				AddressManager.setShowLocalizedAddress(false)
				AddressManager.clearMenuUrlCache()

				sendCustomEvent({
					category: 'error',
					action: 'notification',
					label: title,
					message: errorMsg,
				})

				// remove the error from the url so it isn't shown again if the user reloads the page
				window.history.replaceState({}, '', '/')
			}
		}, [])

		const onSlideChange = useCallback(
			(data) => {
				const index = data?.realIndex
				if (!bannerViewedMap[index] && dealsData?.banners?.length) {
					bannerViewedMap[index] = true

					sendEnhancedEcommerceEvent(
						EEE.sendViewPromotionEvent,
						{ ...dealsData?.banners[index], index },
						{
							currency: Infra?.appParams?.currency,
							brand: Infra?.appParams?.brand,
							countryCode: Infra?.locale?.region,
							locale: Infra?.locale?.msg,
						}
					)
				}
			},
			[dealsData, Infra?.appParams]
		)

		/**
		 * background image should come from params.json - both mobile and desktop
		 * for now for backwards compatibility we check if it doesnt exist in params.json
		 * and use the background images url from getLogo API
		 * the goal is to remove getLogo altogether
		 */
		const isMobileView = !useMediaQuery({ query: mediaQueriesBreakpoints.lg })
		const desktopBackgroundImageUrl = Infra.appParams?.assets?.homepageBackgroundDesktop || logo?.backgroundImageUrl
		const backgroundImageUrl = isMobileView
			? Infra.appParams?.assets?.homepageBackgroundMobile || logo?.mobileImageUrl || desktopBackgroundImageUrl
			: desktopBackgroundImageUrl

		const globalMessageLabels = {
			globalMessage: getTranslatedTextByKey('webflowHomeGeneralMessage') || '',
		}
		const globalMessage =
			getTranslatedTextByKey('webflowHomeGeneralMessage') && User.preferredLanguage ? (
				<IntlProvider messages={globalMessageLabels} locale={User.preferredLanguage} defaultLocale="en">
					<FormattedMessage
						id="globalMessage"
						defaultMessage={getTranslatedTextByKey('webflowHomeGeneralMessage')}
						values={{
							newline: <br />,
						}}
					/>
				</IntlProvider>
			) : (
				''
			)

		const storesClosedDialog = () => {
			const title = getTranslatedTextByKey('webviewFlow.storesAreClosed', 'sorry all our stores are currently closed')
			const message = getTranslatedTextByKey('webviewFlow.orderMessage', 'Please order during our working hours')

			sendCustomEvent({
				category: 'error',
				action: 'notification',
				label: title,
				message,
			})

			Infra.setNotification({
				open: true,
				title,
				message,
				topImg: <StoreClosedDialogImg />,
				customAction: () => {
					Infra.closeNotification()
					redirectToChainMenu(localeToCode[locale.msg], router)
				},
				customButtonType: 'secondary',
				cancelText: getTranslatedTextByKey('eCommerce.storeLocator.browseMenu', 'Browse Menu'),
				showCancelButton: false,
				closeAction: true,
				onClose: () => {
					setIsStoresClosedDialog(false)
					Infra.closeNotification()
				},
			})
		}

		const fetchDeals = async () => {
			try {
				if (typeof Application.isMobileApp !== 'undefined') {
					const channelType = Application.isMobileApp ? CONSTANTS.CHANNEL_TYPE.APP : CONSTANTS.CHANNEL_TYPE.WEB // on Home page channelType can be only Web or App
					// get deals, assign them to state
					const response = await sendRequest(
						false,
						`${getECommerceDomainByEnv()}/v1/tenants/${Infra.appParams?.c}/deals2?lang=${Infra.appParams?.l}&channelType=${channelType}`,
						'get',
						{
							'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
						},
						undefined,
						undefined,
						undefined,
						undefined,
						false
					)
					if (response) {
						setDealsData({
							banners: response?.banners ?? [],
						})
					}
				}
			} catch (error) {
				console.error(error)
			}
		}

		const fetchFeaturedItems = async () => {
			const { l: lang, c: chainId } = Infra.appParams
			const channelType = Application.isMobileApp ? CONSTANTS.CHANNEL_TYPE.APP : CONSTANTS.CHANNEL_TYPE.WEB // on Home page channelType can be only Web or App
			const queryParams = `chainId=${chainId}&lang=${lang}&channelType=${channelType}&type=getFeaturedItems&cust=openRest`
			const response = await sendRequest(false, `${getDomainByEnv()}webFlowAddress?${queryParams}`, 'get', null, null, true, 90000, null, false)
			Home.filterAndSetFeaturedItems(response)
		}

		useEffect(() => {
			const orderType = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP

			initOrUpdateSession({ refObject: { orderType }, shouldRedirectIfError: false })
				.then((menuURL) => {
					if (!Application?.isMobileApp) {
						return
					}

					const userConsistentId = menuURL && queryString.parse(menuURL)?.userConsistentId

					if (userConsistentId) {
						ReactNativeComms.sendMessage.setCUID(userConsistentId, Account?.user?.user?.email) // setCUID for mobile analytics
						const userConsistentIdChanged = User.getUserConsistentId() !== userConsistentId
						User.setUserConsistentId(userConsistentId)

						setTimeout(() => {
							const showSpecialDealsAndDiscountsPopup = Account.checkPushNotificationsStatus(
								MobileApplication.pushNotificationSupported
							)
							if (showSpecialDealsAndDiscountsPopup) {
								Infra.setNotification({
									open: true,
									message:
										User?.userLocaleLanguage?.eCommerce?.notificationPermission ||
										'Would you like to be notified about our special deals and discounts ?',
									cancelAction: () => {
										Infra.closeNotification()
										MobileApplication.setPushNotificationsDeclineDate()
									},
									onClose: () => {
										MobileApplication.setPushNotificationsDeclineDate()
									},
									okAction: () => {
										ReactNativeComms.sendMessage.askPushNotifications()
										Infra.closeNotification()
									},
									okText: getTranslatedTextByKey('btns.yes', 'Yes'),
									cancelText: getTranslatedTextByKey('btns.no', 'No'),
									showCancelButton: true,
								})
							} else if (Account.getPushNotificationsStatus() === 'accepted' && userConsistentIdChanged) {
								ReactNativeComms.sendMessage.askPushNotifications()
							}
						}, 1000)
					}
				})
				.catch((error) => {
					console.error(error)
				})

			fetchFeaturedItems()
		}, [])

		useEffect(() => {
			fetchDeals()
		}, [Application.isMobileApp])

		useEffect(() => {
			if (featuredItems.msg && featuredItems.msg.items) {
				let itemtype = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				if (AddressManager.isUserLocalized()) {
					itemtype = AddressManager.getLocalizedOrderType() === ORDER_TYPES.DELIVERY ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PEAKUP
				}

				setLimnitedOffersState({
					items: featuredItems.msg && featuredItems.msg.items[itemtype],
					randomStore: featuredItems.msg && { ...featuredItems.msg, locale: locale.msg },
				})
			}
		}, [featuredItems.msg, User.getOrderType()])

		const displayClosedStoresDialog = (val) => {
			setIsStoresClosedDialog(val)
		}

		const { couponFlowStore } = useStores()

		const handleHomePageCarouselItemClick = async (item) => {
			if (isStoreClosed) {
				displayClosedStoresDialog(true)
				return
			}

			const item_code = item.description?.en_ID

			if (item_code) {
				couponFlowStore.start({ code: item_code, orderTypeToForce: null, openModal: false })
			} else {
				this.setClickedFeaturedItem(null)
				console.error(`item id: ${item.id} is missing its description.en_ID so cannot use it as a limited time offer!`)
			}
		}

		const onBannerAndOfferClick = async (banner: Banner) => {
			if (!isStoreClosed) {
				sendEnhancedEcommerceEvent(EEE.sendSelectPromotionEvent, banner, {
					currency: Infra?.appParams?.currency,
					brand: Infra?.appParams?.brand,
					countryCode: Infra?.locale?.region,
					locale: Infra?.locale?.msg,
				})

				const couponCode = extractCouponCodeFromOffer(banner, router)
				if (!couponCode) {
					return
				}

				couponFlowStore.start({ code: couponCode, orderTypeToForce: null, openModal: false })

				// handleFeaturedItem()
			} else {
				displayClosedStoresDialog(true)
			}
		}

		const isEcommerceFooterVisible = !isGeneratedStatically && !Application.isMobileApp && Infra.appParams?.eCommerce && Infra.eCommerceFooter

		return (
			<Container $isBackdropEnabled={backdropEnabled} $isEcommerceFooterVisible={isEcommerceFooterVisible} id="homeContainer">
				<NewHeaderLayout Home={Home} isGeneratedStatically={isGeneratedStatically} />
				{(isStoreClosed || globalMessage) && (
					<SubHeader $isStoreClosed={isStoreClosed}>
						<>
							{!isStoreClosed && globalMessage && <GlobalMessage useSubHeader>{globalMessage}</GlobalMessage>}
							{isStoreClosed && <GlobalMessage useSubHeader>{Home.closedMessage}</GlobalMessage>}
						</>
					</SubHeader>
				)}
				<Backdrop open={backdropEnabled} id="home-page-backdrop" data-testid="homepage-backdrop" />

				{Infra.appParams?.features?.enableHomePageBannerCarousel && (
					<Banners
						banners={dealsData?.banners || []}
						onBannerAndOfferClick={onBannerAndOfferClick}
						homepageBanners
						onSlideChange={onSlideChange}
					/>
				)}

				{isStoresClosedDialog && storesClosedDialog()}

				{Account.userExists && (
					<FeatureFlag flag={FeatureFlagEnum.SHOW_PAST_ORDERS_ON_HOME_PAGE}>
						<LTOLayout>
							<PastOrdersSection />
							<SpaceRemover />
						</LTOLayout>
					</FeatureFlag>
				)}

				<LTOLayout>
					<WithInView callbackOnVisible={() => isEmpty(Home.featuredItems) && fetchFeaturedItems()}>
						{limnitedOffersState.randomStore && featuredItems.msg ? (
							<LimitedOffersSection
								title={getTranslatedTextByKey('webviewFlow.limitedTimeOffers')}
								locale={locale.msg}
								onItemClick={handleHomePageCarouselItemClick}
								randomStore={limnitedOffersState.randomStore}
								limitedOffers={limnitedOffersState.items || []}
								deliveryMethod={User.orderType}
							/>
						) : (
							<StyledLimitedOffersLoaderContainer $isMobileView={isMobileView}>
								<LimitedTimeOfferLoader height={isMobileView ? 160 : 295} />
							</StyledLimitedOffersLoaderContainer>
						)}
					</WithInView>
				</LTOLayout>

				<FeatureFlag flag={FeatureFlagEnum.ENABLE_BROWSE_CATEGORIES}>
					<Layout>
						<BrowseCategoriesSection />
					</Layout>
				</FeatureFlag>
			</Container>
		)
	})
)

export default HomeContent
