import React, { useState, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { getECommerceDomainByEnv, getTranslatedTextByKey } from '../../../utils/utils'
import SimpleCarousel from 'components/common/SimpleCarousel'
import Order from 'components/account/pastOrders/Order'
import SectionTitleWithDivider from 'components/common/SectionTitleWithDivider'
import TypographyPro from 'themes/TypographyPro'
import type { InfraProps } from 'mobx/Infra/Infra.type'
import type { AccountInterface } from 'mobx/Account/Account.type'
import type { OrderEntity, PastOrdersResponse } from 'types/OrderEntity'
import { PastOrdersResponsePaging } from 'types/OrderEntity'

const OffersWrapper = styled.div<{ $vertical?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	z-index: 97;
	${({ $vertical }) => $vertical && 'margin-bottom: unset; margin-top: unset;padding: unset;'};

	@media (max-width: 576px) {
		padding-top: 0;
		margin-top: 0;
	}
`

const TitleCarouselWrapper = styled.div<{ $vertical?: boolean }>`
	margin-top: 20px;
	margin-bottom: -10px;
	padding: 0 20px;
	${({ $vertical }) => $vertical && 'margin-bottom: 40px; margin-top: unset;text-indent:unset;'};

	@media (min-width: 375px) and (max-width: 899px) {
		padding: 0 20px;
	}

	@media (min-width: 320px) and (max-width: 374px) {
		padding: 0 10px;
	}
`

const ItemNumberText = styled(TypographyPro)`
	margin-top: 2px;
	color: var(--fonts);
`

interface PastOrdersSectionProps {
	User: any
	Infra: InfraProps
	Account: AccountInterface
}

const NUMBER_OF_LAST_PAST_ORDERS = 20

const PastOrdersSection = inject(
	'User',
	'Infra',
	'Account',
	'Application'
)(
	observer(({ User, Infra, Account, Application }: PastOrdersSectionProps) => {
		const [pastOrders, setPastOrders] = useState<OrderEntity[]>([])
		const title = getTranslatedTextByKey('eCommerce.accountSettings.pastOrders')
		const ordersCountText = `${pastOrders.length} ${getTranslatedTextByKey('eCommerce.accountSettings.pastOrdersCount', 'order(s)')}`

		useEffect(() => {
			;(async () => {
				try {
					const tenantId = Infra.appParams.c
					const header = await Account.getAuthorizationHeader(Application.backendChannel)
					const pastOrdersPage: PastOrdersResponse = await User.fetchFirstPastOrdersPage(
						false,
						header,
						tenantId,
						NUMBER_OF_LAST_PAST_ORDERS
					)
					setPastOrders(pastOrdersPage.data)
				} catch (error) {
					console.log(error)
				}
			})()
		}, [])

		if (pastOrders.length === 0) {
			return null
		}

		return (
			<OffersWrapper>
				<TitleCarouselWrapper>
					<SectionTitleWithDivider title={title} />
					<ItemNumberText variant="BodyRegular">{ordersCountText}</ItemNumberText>
				</TitleCarouselWrapper>

				<SimpleCarousel>
					{pastOrders && pastOrders.map((item: any, index: number) => <Order orderData={item} isHomePage key={index} />)}
				</SimpleCarousel>
			</OffersWrapper>
		)
	})
)

export default PastOrdersSection
